import React, { useEffect, useState } from "react";
import "./index.scss";

export default function PageNotFound() {
  const [gifUrl, setGifUrl] = useState(""); // State to hold the GIF URL

  useEffect(() => {
    // Function to fetch a random GIF from Giphy using fetch
    const fetchRandomGif = async () => {
      try {
        const apiKey = "PbVv0GW2gEfuzX8iOjRVexkfYvaB7rIw"; // Replace with your Giphy API key
        const response = await fetch(
          `https://api.giphy.com/v1/gifs/random?api_key=${apiKey}&tag=cs2&rating=g`
        );
        const data = await response.json();
        const gifUrl = data.data.images.original.url;
        setGifUrl(gifUrl);
      } catch (error) {
        console.error("Error fetching GIF from Giphy:", error);
      }
    };

    fetchRandomGif();
  }, []); // Empty dependency array means this will only run once

  return (
    <div className="page-not-found">
      <h1>404 - Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      <p>Return to the <a href="/">home page</a>.</p>
      <h4>RANDOM CS GIF</h4>
      {gifUrl ? (
        <img src={gifUrl} alt="Funny CS2 Meme" className="meme-image" />
      ) : (
        <p>Loading a random cs meme for you...</p>
      )}
    </div>
  );
}
