import React from "react";
import "./index.scss";
import { Helmet } from "react-helmet";

export default function ComingSoon() {
  return (
    <div className="coming-soon">
      <Helmet>
        <title>CS2Nades Guide - Coming soon</title>
        <meta
          name="description"
          content="Learn all the best CS2 grenade lineups to improve your game."
        />
      </Helmet>
      <h1>Coming Soon</h1>
      <p>We are working hard to bring you new features. Stay tuned!</p>
    </div>
  );
}
