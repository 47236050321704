import "./index.scss";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./component/Header";
// import Footer from "./component/Footer";

import HomePage from "./pages/Home";
import ComingSoon from "./pages/ComingSoon"; // Import the Coming Soon page
import PageNotFound from "./pages/PageNotFound"; // Import the Coming Soon page
// import AboutUsPage from "./pages/AboutUs";
// import InfoPage from "./pages/Info";
// import ContactPage from "./pages/Contact";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Header />
    <Routes>
      {/* <Route path="/" element={<HomePage />} /> */}
      <Route path="/" element={<ComingSoon />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
    {/* <Footer /> */}
  </BrowserRouter>
);
