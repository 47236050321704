import React, { useState, useEffect } from "react";
import { IonIcon } from "@ionic/react";
import { searchOutline, settingsOutline } from "ionicons/icons";
import LogoWH from "../../images/logo/LogoWH.webp"; // Import White Mode Logo
import LogoBL from "../../images/logo/LogoBL.webp"; // Import Black Mode Logo
import "./index.scss";

export default function Header() {
  const [darkMode, setDarkMode] = useState(() => {
    // Get the initial value from local storage, default to false if not found
    return localStorage.getItem("darkMode") === "false";
  });

  useEffect(() => {
    // Apply the dark-mode class to the body if darkMode is true
    if (darkMode) {
      document.body.classList.remove("dark-mode");
    } else {
      document.body.classList.add("dark-mode");
    }
  }, [darkMode]);

  // Handler for toggling dark mode
  const handleToggleDarkMode = () => {
    setDarkMode((prevMode) => {
      localStorage.setItem("darkMode", prevMode); // Save preference in local storage
      return !prevMode;
    });
  };

  return (
    <header className="header">
      {/* Search Icon */}
      <div className="header__icon header__icon--left">
        <IonIcon icon={searchOutline} className="header__ionicon" />
      </div>

      {/* Logo */}
      <div className="header__logo">
        <a
          href={`https://CS2Nades.guide/`} // Create the link to website.com/map
          rel="noopener noreferrer" // Security best practice for external links
        >
        <img
          src={darkMode ? LogoBL : LogoWH }  // Use the black logo in dark mode and the white logo otherwise
          alt="CS2Nades Guide Logo"
          className="header__logo-image"
        />
        </a>
      </div>

      {/* Settings Icon for Dark Mode Toggle */}
      <div
        className="header__icon header__icon--right"
        onClick={handleToggleDarkMode}
      >
        <IonIcon icon={settingsOutline} className="header__ionicon" />
      </div>
    </header>
  );
}
